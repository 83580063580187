import React from 'react';
import {StaticImage} from "gatsby-plugin-image"
import { Container, Row, Col, CardDeck, Card} from 'react-bootstrap';
import '../styles/benefits.css';

import {FaCheck} from "react-icons/fa";

const Benefits = () => {
    return(
        <div className="section-padding">
            <Container className="agency-sec shadow">
                <Row className="service-block">
                    <CardDeck>
                        <Card className="cardBen my-2">
                            <Row>
                                <Col className="col-2">
                                    <StaticImage
                                        src="../images/f1.webp"
                                        placeholder="blurred"
                                        layout="constrained"
                                        loading="eager"
                                        width={50}
                                        alt=""
                                    />
                                </Col>
                                <Col className="col-10">
                                    <Card.Body>
                                        <Card.Title className="cardTitle">Simplicity And Choice</Card.Title>
                                        <Card.Text className="cardText">
                                                Custom websites that are built with your brand & customer in mind
                                        </Card.Text>
                                    </Card.Body>                                    
                                </Col> 
                            </Row>
                            <Row className="justify-content-center">
                                <ul>
                                    <li className="listBen">
                                        <p className="listText"><span><FaCheck className="check mr-1" />Built Around Your Business</span></p>                                                    
                                    </li>
                                    <li className="listBen">
                                        <p className="listText"><span><FaCheck className="check mr-1" /> Fully Customizable</span></p>                                                    
                                    </li>
                                    <li className="listBen">
                                        <p className="listText"><span><FaCheck className="check mr-1" /> Custom Integrations</span></p>                                                    
                                    </li>
                                </ul>
                            </Row>                     
                        </Card>
                        <Card className="cardBen my-2">
                            <Row>
                                <Col className="col-2">
                                    <StaticImage
                                        src="../images/f2.webp"
                                        placeholder="blurred"
                                        loading="eager"
                                        layout="constrained"
                                        width={50}
                                        alt=""
                                    />
                                </Col>
                                <Col className="col-10">
                                    <Card.Body>
                                        <Card.Title className="cardTitle">Worry-Free Experience</Card.Title>
                                        <Card.Text className="cardText">
                                                An experience that gives you all the control without all the stress
                                        </Card.Text>
                                    </Card.Body>                                    
                                </Col> 
                            </Row>
                            <Row className="justify-content-center">
                                <ul>
                                    <li className="listBen">
                                        <p className="listText"><span><FaCheck className="check mr-1" /> High Secuirty</span></p>                                                    
                                    </li>
                                    <li className="listBen">
                                        <p className="listText"><span><FaCheck className="check mr-1" /> Great User Experience</span></p>                                                    
                                    </li>
                                    <li className="listBen">
                                        <p className="listText"><span><FaCheck className="check mr-1" /> Built-In SEO</span></p>                                                    
                                    </li>
                                </ul>
                            </Row>                     
                        </Card>
                        <Card className="cardBen my-2">
                            <Row>
                                <Col className="col-2">
                                    <StaticImage
                                        src="../images/f3.webp"
                                        placeholder="blurred"
                                        loading="eager"
                                        layout="constrained"
                                        width={50}
                                        alt=""
                                    />
                                </Col>
                                <Col className="col-10">
                                    <Card.Body>
                                        <Card.Title className="cardTitle">Performance That Scales</Card.Title>
                                        <Card.Text className="cardText">
                                                Built to scale with your business to perform at blazing fast speeds                                            
                                        </Card.Text>
                                    </Card.Body>                                    
                                </Col> 
                            </Row>
                            <Row className="justify-content-center">
                                <ul>
                                    <li className="listBen">
                                        <p className="listText"><span><FaCheck className="check mr-1" /> Modern Web App Technology</span></p>                                                    
                                    </li>
                                    <li className="listBen">
                                        <p className="listText"><span><FaCheck className="check mr-1" /> Amazing Performance</span></p>                                                    
                                    </li>
                                    <li className="listBen">
                                        <p className="listText"><span><FaCheck className="check mr-1" /> Mobile Responsive</span></p>                                                    
                                    </li>
                                </ul>
                            </Row>                     
                        </Card>
                    </CardDeck>
                </Row>
            </Container>
        </div>
    )
};

export default Benefits;