import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Jumbotron, Container, Row, Col, Button} from 'react-bootstrap';
import '../styles/hero.css'
import 'animate.css'

import useModal from "../components/useModal"
import ModalForm from "../components/modal"

const Hero = () =>  {
        const {isShowing, toggle} = useModal();
        return (
            <Jumbotron className="hero-section" fluid>
                <Container className="hero-container">
                    <Row className="align-items-center">
                        <ModalForm
                            className="modal"
                            isShowing={isShowing}
                            hide={toggle}
                        />
                    </Row>
                    <Row className="h-100 align-items-center header-content-578">
                        <Col sm={12} md={12} lg={6} className="">
                            <div className="welcome-content">
                                <div className="promo-section mb-3">
                                    <h3 className="special-head cyan">Connecting your business with your customers</h3>
                                </div>
                                <h1 className="w-text text-center-578 animate__animated animate__fadeInUp mb-4">Web Design & SEO Agency</h1>
                                <p className="g-text text-center-578 animate__animated animate__fadeInUp">
                                    Connecting your brand to the world. Are you ready to take your business to the next level?
                                </p>
                                <Row className="mt-5 btn-container">
                                    <Button className="more-btn btn mr-3 mx-3 animate__animated animate__fadeInUp" onClick={toggle} aria-label="contact us form popup button">Contact Us</Button>
                                    <Button className="more-btn btn mr-3 mx-3 animate__animated animate__fadeInUp" href="#serviceBlock" aria-label="services block button">Learn More</Button>
                                </Row>
                            </div>
                        </Col>
                        <Col sm={12} lg={6} className="mx-auto">
                            <StaticImage
                                src="../images/banner2.webp"
                                placeholder="blurred"
                                loading="eager"
                                layout="constrained"
                                alt="" 
                                className="banner-img mx-auto" />
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
}

export default Hero;