import React from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import '../styles/contactus.css';
import { navigate } from 'gatsby';

const ContactUs = () => {
    const handleSubmit = async (event) => {
        event.preventDefault();
      
        // extract form data
        const formdata = new FormData(event.target)
      
        // convert FormData to json object
        // SOURCE: https://stackoverflow.com/a/46774073
        const json = {}
        formdata.forEach(function(value, prop){
          json[prop] = value
        })
      
        // convert json to urlencoded query string
        // SOURCE: https://stackoverflow.com/a/37562814 (comments)
        const formBody = Object.keys(json).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(json[key])).join('&')
      
        // POST the request to API endpoint
        const response = await fetch("https://forms.hubspot.com/uploads/form/v2/6796072/55996f3f-5ad7-42ea-a678-83f66c83b0a7", {
          method: "POST",
          mode: "no-cors",
          headers: {"Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin":"*"},
          body: formBody,
        }).then(response => {
            // identify visitor
            const _hsq = window._hsq = window._hsq || [];
                _hsq.push(["identify",{
                    email: formBody.email,
                }]);
            // reset form
            document.getElementById("contactForm").reset()
            navigate("/thank-you")
            // display success message
            // document.getElementById("success").style.display = "block"
            // document.getElementById("modalForm").style.display="none"
            // document.getElementById("headerMsg").style.display="none"
          })
          .catch(error => {
            console.log(error)
            // document.getElementById("failure").style.display = "block"
            // document.getElementById("modalForm").style.display = "none"
            // document.getElementById("headerMsg").style.display="none"
          })
      }
    return(
        <div className="contact-area mt-3 pb-4 pt-4">
            <Container>
                <Row className="align-items-center">
                    <Col className="contact-content col-12 col-lg-6 col-sm-12">
                        <h3 className="mb-3 mt-3 contact-text-center">
                            <span style={{color: "cyan", fontWeight: "bold", fontSize: "1rem"}}>Contact Us With Your Questions</span>
                        </h3>
                        <h4 className="contact-htext contact-text-center">Get in Touch with us Easily.</h4>
                        <p className="g-text contact-text-center mb-3">
                            Looking for innovative solutions to scale your business? We’re standing by to answer your questions and architect your digital blueprint for domination. 
                        </p>
                        <Form inline className="contact-section" method="POST" onSubmit={handleSubmit} id="contactForm">
                            <Row className="form-row">
                                <Form.Group className="my-1" controlId="formBasicEmail">
                                    <Form.Control aria-label="email" className="contact-input mr-2" name="email" type="email" placeholder="Enter email" required/>
                                </Form.Group>
                            </Row>
                            <Button className="contact-btn my-1" type="submit" aria-label="form submission button">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                    
                </Row>
                
            </Container>
        </div>
    )
};

export default ContactUs;