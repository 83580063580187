import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Container, Row, Col, Card, CardDeck} from 'react-bootstrap';
import {FaLongArrowAltRight} from 'react-icons/fa'
import 'animate.css';

import '../styles/servicesblock.css'

const ServiceBlock = () => {
    return(
        <div className=" mt-5" id="serviceBlock">
            <Container className="service-section shadow">
                <Row className="justify-content-center mb-5">
                    <Col className="service-heading text-center">
                        <div>
                            <span className="gradient-text blue">
                                Our Services
                            </span>
                        </div>
                        <h2 className="svc-h2 mt-3 mb-3">
                        One-Stop Digital Marketing Station
                        </h2>
                        <p className="svc-text">
                            At Codev Media, we offer a seamless integration of services that enhance your brand and your visibility. Each of our comprehensive services provides critical components that all make a difference in your ROI. As a top-tier digital marketing agency, we use only the best tools and are always up to date with every technological advancement. 
                        </p>
                    </Col>
                </Row>
                <CardDeck className="mb-5">
                <Card className="svc-card shadow">
                        <Card.Body className="mt-2">
                            <StaticImage
                                src="../images/programming.webp"
                                placeholder="blurred"
                                layout="constrained"
                                className="svc-img"
                                alt=""
                            />
                            <Row className="mb-4 justify-content-center">
                                <Col className="col-10 text-center">
                                    <Card.Title className="cardTitle">Custom Coding</Card.Title>
                                    <Card.Text className="cardText">
                                        Each website is custom-coded specifically for your brand’s needs.
                                    </Card.Text>
                                </Col>
                            </Row>
                            <a href="/services" className="svc-icon" aria-label="services page button">
                                <FaLongArrowAltRight className="arrow" />
                            </a>
                        </Card.Body>
                    </Card>
                    <Card className="svc-card shadow">
                        <Card.Body className="mt-2">
                            <StaticImage
                                src="../images/funnel.webp"
                                placeholder="blurred"
                                layout="constrained"
                                className="svc-img"
                                alt=""
                            />
                            <Row className="mb-4 justify-content-center">
                                <Col className="col-10 text-center">
                                    <Card.Title className="cardTitle">Sales Funnels</Card.Title>
                                    <Card.Text className="cardText">
                                        We use state-of-the-art tools to create the highest-converting sales funnels around.
                                    </Card.Text>
                                </Col>
                            </Row>
                            <a href="/services" className="svc-icon" aria-label="services page button">
                                <FaLongArrowAltRight className="arrow" />
                            </a>
                        </Card.Body>
                    </Card>
                    <Card className="svc-card shadow">
                        <Card.Body className="mt-2">
                            <StaticImage
                                src="../images/copywriting.webp"
                                placeholder="blurred"
                                layout="constrained"
                                className="svc-img"
                                alt=""
                            />
                            <Row className="mb-4 justify-content-center">
                                <Col className="col-10 text-center">
                                    <Card.Title className="cardTitle">Copywriting</Card.Title>
                                    <Card.Text className="cardText">
                                    No website is complete without compelling copy to engage your visitors. 
                                    </Card.Text>
                                </Col>
                            </Row>
                            <a href="/services" className="svc-icon" aria-label="services page button">
                                <FaLongArrowAltRight className="arrow" />
                            </a>
                        </Card.Body>
                    </Card>                           
                </CardDeck>
                <CardDeck className="mb-5">
                    <Card className="svc-card shadow">
                        <Card.Body className="mt-2">
                            <StaticImage
                                src="../images/payment-gateway.webp"
                                placeholder="blurred"
                                layout="constrained"
                                className="svc-img"
                                alt=""
                            />
                            <Row className="mb-4 justify-content-center">
                                <Col className="col-10 text-center">
                                    <Card.Title className="cardTitle">Payment Gateway</Card.Title>
                                    <Card.Text className="cardText">
                                        We’ll hook you up with the payment systems your e-commerce site needs.
                                    </Card.Text>
                                </Col>
                            </Row>
                            <a href="/services" className="svc-icon" aria-label="services page button">
                                <FaLongArrowAltRight className="arrow" />
                            </a>
                        </Card.Body>
                    </Card>
                    <Card className="svc-card shadow">
                        <Card.Body className="mt-2">
                            <StaticImage
                                src="../images/analytics.webp"
                                placeholder="blurred"
                                layout="constrained"
                                className="svc-img"
                                alt=""
                            />
                            <Row className="mb-4 justify-content-center">
                                <Col className="col-10 text-center">
                                    <Card.Title className="cardTitle">Tracking Code Setup</Card.Title>
                                    <Card.Text className="cardText">
                                    This little piece of code will track all of your Facebook & Google conversions.
                                    </Card.Text>
                                </Col>
                            </Row>
                            <a href="/services" className="svc-icon" aria-label="services page button">
                                <FaLongArrowAltRight className="arrow" />
                            </a>
                        </Card.Body>
                    </Card>
                    <Card className="svc-card shadow">
                        <Card.Body className="mt-2">
                            <StaticImage
                                src="../images/email-crm.webp"
                                placeholder="blurred"
                                layout="constrained"
                                className="svc-img"
                                alt=""
                            />
                            <Row className="mb-4 justify-content-center">
                                <Col className="col-10 text-center">
                                    <Card.Title className="cardTitle">Email & CRM Integrations</Card.Title>
                                    <Card.Text className="cardText">
                                        Got email? You will with our seamless email integrations. 
                                    </Card.Text>
                                </Col>
                            </Row>
                            <a href="/services" className="svc-icon" aria-label="services page button">
                                <FaLongArrowAltRight className="arrow" />
                            </a>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>
        </div>
    );
};

export default ServiceBlock;