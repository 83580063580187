import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {Container, Row, Col} from "react-bootstrap";
import "../styles/partners.css";
import "animate.css";

const Partners = () => {
    return (
        <div className="partners-section mt-5">
            <Container className="partners-container">
                <Row className="justify-content-center">
                    <div>
                        <span className="partner-grad">
                            Parnters & Tools
                        </span>
                    </div>
                </Row>
                <Row className="justify-content-center text-center mt-3">
                    <h2>
                        Our Powerful Partners & Tools
                    </h2>
                </Row>
                <Row className="justify-content-center">
                    <Col className="col-8">
                        <p className="partner-text text-center">
                            As the top SEO agency Oklahoma City has to offer, we work with only the most cutting-edge technology and platforms to connect you with your users and help you convert. 
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-center align-items-center">
                    <Col xs={7} sm={6} md={3}>
                        <a href="https://www.github.com" target="_blank" rel="noreferrer">
                            <StaticImage
                                src="../images/github_PNG15.webp"
                                placeholder="blurred"
                                layout="constrained"
                                alt="Github web development repository button"
                            />
                        </a>
                    </Col>
                    <Col xs={7} sm={6} md={3}>
                        <a href="https://www.gatsbyjs.com" target="_blank" rel="noreferrer">
                            <StaticImage
                                src="../images/gatsby-logo.webp"
                                placeholder="blurred"
                                layout="constrained"
                                alt="Gatsby font-end website framework button"
                            />
                        </a>
                    </Col>
                    <Col xs={7} sm={6} md={3}>
                        <a href="https://www.netlify.com" target="_blank" rel="noreferrer">
                            <StaticImage
                                src="../images/full-logo-light.webp"
                                placeholder="blurred"
                                layout="constrained"
                                alt="Netlify cdn web hosting button"
                            />
                        </a>
                    </Col>
                    <Col xs={7} sm={6} md={3}>
                        <a href="https://groovepages.groovesell.com/a/Fx8dWAE5lEz0" target="_blank" rel="noreferrer">
                            <StaticImage
                                src="../images/groove-logo.webp"
                                placeholder="blurred"
                                layout="constrained"
                                alt="Groovefunnels sales funnel design button"
                            />
                        </a>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={8} md={6}>
                        <StaticImage
                                src="../images/cm3-edited.webp"
                                placeholder="blurred"
                                layout="constrained"
                                alt=""
                            />
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Partners;
