import React from "react";
import {StaticImage} from 'gatsby-plugin-image';
import ReactDOM from 'react-dom';
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import '../styles/modal.css'
import { navigate } from "gatsby";

const handleSubmit = async (event) => {
  event.preventDefault();

  // extract form data
  const formdata = new FormData(event.target)

  // convert FormData to json object
  // SOURCE: https://stackoverflow.com/a/46774073
  const json = {}
  formdata.forEach(function(value, prop){
    json[prop] = value
  })

  // convert json to urlencoded query string
  // SOURCE: https://stackoverflow.com/a/37562814 (comments)
  const formBody = Object.keys(json).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(json[key])).join('&')

  // POST the request to API endpoint
  const response = await fetch("https://forms.hubspot.com/uploads/form/v2/6796072/e441472f-b7a1-493a-b389-7b3c1ee94468", {
    method: "POST",
    mode: "no-cors",
    headers: {"Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin":"*"},
    body: formBody,
  }).then(response => {
      // identify visitor
      const _hsq = window._hsq = window._hsq || [];
          _hsq.push(["identify",{
              email: formBody.email,
          }]);
      // reset form
      document.getElementById("modalForm").reset()
      navigate("/thank-you")
      // display success message
      // document.getElementById("success").style.display = "block"
      // document.getElementById("modalForm").style.display="none"
      // document.getElementById("headerMsg").style.display="none"
    })
    .catch(error => {
      console.log(error)
      // document.getElementById("failure").style.display = "block"
      // document.getElementById("modalForm").style.display = "none"
      // document.getElementById("headerMsg").style.display="none"
    })
}

const ModalForm = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(
    <React.Fragment>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
      </div>
      <Modal 
        show={isShowing} 
        onHide={hide}
        size="xl"
        centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-white modal-title">Work With Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center px-5">
            <Col className="">
              <StaticImage
                src="../images/about-5.webp"
                placeholder="blurred"
                layout="constrained"
                alt=""
                className="mx-auto"
              />
              <p className="text-white modal-text-center">
                Let’s face it. You need the digital marketing tools only the professionals at Codev Media can give you. We’re about to make scaling your business easy.
              </p>
              <p className="text-white pb-2 modal-text-center">
                Fill out this simple form for a FREE 30-minute consultation.
              </p>
              <p className="text-white pb-2 modal-text-center">
                Contact us today!
              </p>
            </Col>
            <Col className="">
              <Form onSubmit={handleSubmit} method="POST" id="modalForm">
                <Form.Row>
                  <Form.Group as={Col} controlId="firstname">
                    <Form.Control aria-label="firstname" name="firstname" type="text" placeholder="First Name..." className="formInput" required/>
                  </Form.Group>
                  <Form.Group as={Col} controlId="lastname">
                    <Form.Control aria-label="lastname" name="lastname" type="text" placeholder="Last Name..." className="formInput" required/>
                  </Form.Group>
                </Form.Row>
                
                <Form.Group controlId="formEmail">
                  <Form.Control aria-label="email" name="email" type="email" placeholder="Email..." className="formInput" required/>
                </Form.Group>

                <Form.Group controlId="formPhone">
                  <Form.Control aria-label="phone" name="phone" type="tel" placeholder="Phone Number..." className="formInput" required/>
                </Form.Group>

                <Form.Group controlId="formBudget">
                    <Form.Label className="text-white">What is your budget?</Form.Label>
                    <Form.Control name="budget" type="number" placeholder="Budget..." className="formInput" required/>
                </Form.Group>

                <Form.Group controlId="FormProject">
                    <Form.Label className="text-white">Your Project & Goals</Form.Label>
                    <Form.Control aria-label="project&goals" name="project&goals" rows={5} as="textarea" placeholder="What are your goals?" className="formInput" required/>
                </Form.Group>
                <Form.Row className="justify-content-center">
                <Button className="contact-btn" type="submit">
                    Send Message
                </Button>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>, document.body
  ): null;

export default ModalForm;