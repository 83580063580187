import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Benefits from "../components/benefits"
import AboutUs from "../components/aboutus"
import ContactUs from "../components/contactus"
import ServiceBlock from "../components/servicesblock"
import Partners from "../components/partners"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <Layout>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://www.codevmedia.com",
            "name": "Codev Media",
            "sameAs": [
              "https://www.facebook.com/CodevMediaOKC",
              "https://www.twitter.com/CodevMediaOKC",
              "https://www.instagram.com/codevmedia/",
              "https://www.youtube.com/channel/UC1I0hmrtcbZ3E77F9Vva6Ww"
            ]
          }          
        `}
      </script>
    </Helmet>
    <SEO title="Web Design & SEO Agency Oklahoma City" />
    <Hero />
    <Benefits />
    <AboutUs />
    <ContactUs />
    <ServiceBlock />
    <Partners />
  </Layout>
)

export default IndexPage
