import React from 'react';
import {StaticImage} from 'gatsby-plugin-image'
import {Container, Row, Col} from 'react-bootstrap';
import '../styles/aboutus.css';
import 'animate.css';
import {BsCheckBox} from 'react-icons/bs';

const AboutUs = () => {
    return(
        <div className="section-padding mt-5">
            <Container className="section-container">
                <Row className="align-items-center">
                    <Col md={12} lg={6}>
                        <div className="animate__animated animate__fadeInUp">
                            <StaticImage
                                src="../images/digital-5.webp"
                                className="mx-auto"
                                layout="constrained"
                                placeholder="blurred"
                                width={655}
                                alt=""
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="working-together">
                            <div className="dream-txt mb-3 text-center">
                                <span className="gradient-text blue">Working Closely Together</span>
                            </div>
                            <h2 className="about-hText text-center">
                                Codev Media Makes Digital Marketing Easy
                            </h2>
                            <p className="about-p">
                                Back away from the computer. You don’t need to be an IT genius to have a website that boosts your brand and converts sales. That’s why we’re here to help. Let us be your strategic digital partner to make sure that your brand’s story is communicated effectively and your exposure is increased.  
                            </p>
                            <Row className="justify-items-center">
                                <Col>
                                    <ul>
                                        <li className="aboutListBen">
                                            <p className="aboutListText"><span><BsCheckBox className="aboutCheck mr-1"/></span> Website Design Services</p>
                                        </li>
                                        <li className="aboutListBen">
                                            <p className="aboutListText"><span><BsCheckBox className="aboutCheck mr-1"/></span> Sales Funnel Services</p>
                                        </li>
                                        <li className="aboutListBen">
                                            <p className="aboutListText"><span><BsCheckBox className="aboutCheck mr-1"/></span> Search Engine Optimization</p>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul>
                                        <li className="aboutListBen">
                                            <p className="aboutListText"><span><BsCheckBox className="aboutCheck mr-1"/></span> Web Development</p>
                                        </li>
                                        <li className="aboutListBen">
                                            <p className="aboutListText"><span><BsCheckBox className="aboutCheck mr-1"/></span> Email Integration</p>
                                        </li>
                                        <li className="aboutListBen">
                                            <p className="aboutListText"><span><BsCheckBox className="aboutCheck mr-1"/></span> Digital Advertising</p>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default AboutUs;